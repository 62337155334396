<template>
  <div>
    <slot name="button" :openModal="openModal"></slot>

    <b-modal
      :id="`sale-express-modal`"
      size="lg"
      :title="$t('shared.modal_sale_express.title')"
      :no-close-on-backdrop="modalLoading"
    >
      <b-container>
        <b-row>
          <b-col>
            <div class="callout callout-success">
              <p class="modal-subtitle">{{ $t('shared.modal_sale_express.subtitle') }}</p>
            </div>
          </b-col>
        </b-row>

        <!-- Products -->
        <b-row class="mb-2">
          <b-col cols="10">
            <b-form-group :label="$t('shared.modal_sale_express.products_select')">
              <multiselect
                id="selectProducts"
                v-model="selectedProduct"
                v-validate="'required'"
                label="name"
                track-by="id"
                :placeholder="$t('shared.modal_sale_express.search_products')"
                :selectLabel="$t('shared.modal_sale_express.select_click')"
                :deselectLabel="$t('shared.modal_sale_express.remove_click')"
                selectedLabel="✔️"
                open-direction="bottom"
                :options="products"
                :multiple="false"
                :searchable="true"
                :loading="loading"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :show-no-results="true"
                :hide-selected="true"
                :option-height="104"
                :disabled="modalLoading"
                :class="['multiselect_product', {'heaven': isHeaven}]"
                @select="fetchOffers"
                @search-change="handleProductsFilter"
              >
                <template slot="option" slot-scope="props">
                  <div class="d-flex align-items-center div_description">
                    <div style="min-width:90px !important;">
                      <img class="option__image" :src="props.option.img" :alt="props.option.name">
                    </div>
                    <div class="option_description">
                      <p class="p_description">{{ props.option.name }}</p>
                      <p class="p_description">{{ props.option.desc }}</p>
                    </div>
                  </div>
                </template>
                <span slot="noOptions">{{ $t("shared.modal_sale_express.search_products") }}</span>
                <span slot="noResult">{{ $t("seller.sales.text_2633") }}</span>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <label></label>
            <BaseButton
              class="mt-4 ml-2 w-100"
              style="font-size: 25px"
              :variant="isHeaven ? 'heaven' : 'primary'"
              :disabled="modalLoading"
              @click="handleAddProduct()"
              id="AddProduct">
              {{ '+' }}
            </BaseButton>
            <b-tooltip target="AddProduct" triggers="hover">
              {{ $t('shared.modal_sale_express.button_add_product') }}
            </b-tooltip>
          </b-col>
        </b-row>

        <!-- Offers -->
        <b-row class="mb-2">
          <b-col cols="10">
            <b-form-group :label="$t('shared.modal_sale_express.offers_select')">
              <multiselect
                id="selectOffers"
                v-model="selectedOffer"
                v-validate="'required'"
                label="name"
                track-by="id"
                :placeholder="$t('shared.modal_sale_express.search_offers')"
                :selectLabel="$t('shared.modal_sale_express.select_click')"
                :deselectLabel="$t('shared.modal_sale_express.remove_click')"
                selectedLabel="✔️"
                open-direction="bottom"
                :options="offers"
                :multiple="false"
                :searchable="true"
                :loading="offersLoading"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :show-no-results="true"
                :hide-selected="true"
                :disabled="isOffersSelectDisabled || modalLoading"
                :class="['multiselect_product', {'heaven': isHeaven}]"
              >
                <span slot="noOptions">{{ $t("shared.modal_sale_express.search_offer") }}</span>
                <span slot="noResult">{{ $t("seller.sales.text_2657") }}</span>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <label></label>
            <BaseButton
              class="mt-4 ml-2 w-100"
              style="font-size: 25px"
              :variant="isHeaven ? 'heaven' : 'primary'"
              @click="handleAddOffer"
              :disabled="isOffersSelectDisabled || modalLoading"
              id="AddOffer">
              {{ '+' }}
            </BaseButton>
            <b-tooltip target="AddOffer" triggers="hover">
              {{ $t('shared.modal_sale_express.button_add_offer') }}
            </b-tooltip>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <BaseButton
          variant="link-info"
          class="mr-4"
          :disabled="modalLoading"
          @click="cancel()"
        >
          {{ $t('shared.text_1993') }}
        </BaseButton>
        <BaseButton
          variant="primary"
          :class="{'heaven': isHeaven}"
          :loading="modalLoading"
          :disabled="modalLoading"
          @click="onSubmit"
        >
          {{ $t('shared.modal_sale_express.button_send') }}
        </BaseButton>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import Money from "@/mixins/money";
import Multiselect from "vue-multiselect";

import ProductService from "@/services/resources/ProductService";

const serviceProduct = ProductService.build();

import SaleExpressService from "@/services/resources/SaleExpressService";

const serviceSaleExpress = SaleExpressService.build();

import BaseButton from "../../BaseButton.vue";
import {mapGetters} from "vuex";

export default {
  name: 'SaleExpress',
  components: {BaseButton, Multiselect},
  mixins: [Money],
  data() {
    return {
      products: [],
      selectedProduct: null,
      offers: [],
      selectedOffer: null,
      loading: false,
      offersLoading: false,
      modalLoading: false
    }
  },
  computed: {
    ...mapGetters(['getFlags']),
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isOffersSelectDisabled() {
      return !this.selectedProduct;
    }
  },
  methods: {
    handleAddProduct() {
      window.open('/novo-produto', '_blank');
    },
    handleAddOffer() {
      const url = `/produtos/edicao-produto/${this.selectedProduct.id}?from=sale_express`;
      window.open(url, '_blank');
    },
    fetchProducts(search = null) {
      let data = {list: true}
      if (search) data.search = search
      this.products = []

      serviceProduct
        .search({...data, with_participants: true})
        .then((response) => {
          response.forEach((element) => {
            const format = element.format;
            // Filter products that are not allowed to be sold in the second sale
            if (this.getFlags.includes("feature_tickets") && format === 'EVENT') {
              return;
            }
            this.products.push({
              id: element.id,
              name: element.name,
              desc: element.description.substr(0, 30) + '...',
              img: element.images && element.images.length !== 0 && element.images[0].path ? element.images[0].path : ''
            })
          })
        })
        .catch((err) => {
          console.error(err)
          this.products = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleProductsFilter(query) {
      this.loading = true;
      this.search = query;
      this.filterProducts();
    },
    filterProducts: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),
    async fetchOffers(product) {
      this.offersLoading = true
      this.offers = [];
      this.selectedOffer = null;

      serviceProduct.search({list: true, url: `${product.id}/offers`})
        .then(res => {
          let offers_map = res.map((offer) => {
            if (product.id == offer.product_id) {
              return {id: offer.id, name: offer.name}
            }
          })
          this.offers.push(...offers_map)
        })
        .catch((e) => {
          console.error('Error trying to fetch offers: ' + e.message);
          this.offers = []
        })
        .finally(() => {
          this.offersLoading = false;
        });
    },
    filterOffers: _.debounce(function () {
      this.fetchOffers();
    }, 500),
    openModal(sale_id) {
      this.loading = true;
      this.resetLocalState();
      this.$bvModal.show('sale-express-modal')
      this.fetchProducts();
      this.sale_id = sale_id
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$bvToast.toast(this.$t('shared.modal_sale_express.validation_warning_body'), {
            title: this.$t('shared.modal_sale_express.validation_warning_title'),
            variant: "warning",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }

        this.modalLoading = true;

        let data = {
          sale_id: this.sale_id,
          product_id: this.selectedProduct.id,
          offer_id: this.selectedOffer.id
        };

        serviceSaleExpress
          .post('', data)
          .then((response) => {
            this.$bvToast.toast(this.$t('shared.modal_sale_express.validation_success_body'), {
              title: this.$t('shared.modal_sale_express.validation_success_title'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide('sale-express-modal');
            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
            this.$bvModal.hide('sale-express-modal');
            this.$emit("close");
          }).finally(() => {
             this.modalLoading = false;
          });
      });
    },
    resetLocalState() {
      this.offers = [];
      this.selectedOffer = null;
      this.products = [];
      this.selectedProduct = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.Affiliate-item {
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: #eee;
    color: #111;
  }
}

.active {
  background: $base-color;
  color: #fff;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

.modal-subtitle {
  padding: 10px 0;
}
</style>
