<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <b-row v-if="openType !== 'lead'" class="item-dados">
                <b-col class="sumary">
                  <b-row v-if="sale.type === 'SUBSCRIPTION' && id_contract" class="mb-3" data-anima="top">
                    <!-- Bound Contract -->
                    <BaseButton
                      variant="info2"
                      id="contrato-vinculado"
                      class="btn-entrega button-info3 btn-link-contract"
                      @click="$router.push('/contratos?c_id=' + id_contract)"
                    >
                      <img src="@/assets/img/icons/link-externo2.svg" />
                      {{ $t('shared.text_2053') }} #{{ id_contract }}
                    </BaseButton>
                    <b-tooltip target="contrato-vinculado" :title="$t('shared.text_2054')" />
                  </b-row>

                  <b-row class="mb-3">
                    <!-- Code -->
                    <b-col cols="5">
                      <p class="label-campo">{{ $t('shared.text_1996') }}</p>
                      <p class="dados-campo">{{ sale.id }}</p>
                    </b-col>
                    <!-- Status -->
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('shared.text_1997') }}</p>
                      <template>
                        <p class="dados-campo" :class="getStatus(sale.status).class">
                          {{ getStatus(sale.status).text }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Payment -->
                    <b-col v-if="sale.status == 'paid' || sale.status == 'trialing'" cols="5 mb-3">
                      <p class="label-campo">{{ $t('shared.text_2051') }}</p>
                      <p class="dados-campo">{{ sale.paid_at | date }}</p>
                    </b-col>
                    <!-- Creation -->
                    <b-col v-if="sale.status == 'paid' || sale.status == 'trialing'" cols="5">
                      <p class="label-campo">{{ $t('shared.text_2436') }}</p>
                      <p class="dados-campo">{{ sale.created_at | date }}</p>
                    </b-col>
                  </b-row>
                  <!-- Reason for Refusal -->
                  <b-row v-if="sale.status == 'refused'" class="mb-2">
                    <b-col cols="12">
                      <p class="label-campo">{{ $t('shared.text_2055') }}</p>
                      <p class="dados-campo" :class="getStatus(sale.status).class" v-if="sale.error_code_reason">
                        {{ checkError(sale.error_code_reason) }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Gross Value -->
                    <b-col cols="5 mb-3">
                      <p class="label-campo">{{ $t('shared.text_2056') }}</p>
                      <p class="dados-campo" v-if="sale.total && sale.installments > 1">
                        <template v-if="sale.base_currency">
                          {{ sale.base_currency.currency_symbol }}
                        </template>
                        {{ formatMoney(+sale.total) }}
                        <span v-if="sale.installments > 1">
                          {{ $t('shared.text_2057') }} {{ sale.installments }}x
                        </span>
                      </p>
                      <p class="dados-campo" v-else-if="sale.amount">
                        <template v-if="sale.base_currency">
                          {{ sale.base_currency.currency_symbol }}
                        </template>
                        {{ formatMoney(+sale.amount) }}
                        <span v-if="sale.installments > 1">
                          {{ $t('shared.text_2057') }} {{ sale.installments }}x
                        </span>
                      </p>
                      <p v-else class="dados-campo">
                        <template v-if="sale.base_currency">
                          {{ sale.base_currency.currency_symbol }}
                        </template>
                        0,00
                      </p>
                    </b-col>
                    <!-- Current installment -->
                    <!-- <b-col cols="5" v-if="sale.current_installment !== -1 && sale.installments !== 1">
                      <p class="label-campo">{{ $t("views.seller.sales.text_1347") }}</p>
                      <p class="dados-campo">
                        {{ `${sale.current_installment}/${sale.installments}`  }}
                      </p>
                    </b-col> -->
                    <!-- Received Amount -->
                    <b-col cols="5">
                      <p class="label-campo">{{ $t('shared.text_2058') }}</p>
                      <template v-if="sale.method === 'FREE'">
                        <span>{{ $t("seller.products.new.text_662") }}</span>
                      </template>
                      <template v-else>
                        <p v-if="sale.balance" class="dados-campo">
                          <template v-if="sale.base_currency">
                            {{ sale.base_currency.currency_symbol }}
                          </template>
                          {{ formatMoney(+sale.balance) }}
                        </p>
                        <p v-else class="dados-campo">
                          <template v-if="sale.base_currency">
                            {{ sale.base_currency.currency_symbol }}
                          </template>
                          0,00
                        </p>
                      </template>
                    </b-col>
                    <!-- Method -->
                    <b-col cols="5">
                      <p class="label-campo">{{$t('shared.text_2059')}}</p>
                      <p class="dados-campo">
                        {{ getMethod(sale.method) === 'FREE' ? $t('seller.sales.text_2659') : getMethod(sale.method) }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row v-if="sale.method == 'CREDIT_CARD' || sale.method == 'TWO_CREDIT_CARDS'" class="mt-2">
                    <!-- Used Card -->
                    <b-col cols="7">
                      <p class="label-campo">{{$t('shared.text_2060')}}</p>
                      <p class="dados-campo" v-if="sale.card_last_digits">
                        #### #### #### {{ sale.card_last_digits }}
                      </p>
                      <p class="dados-campo" v-else>---</p>
                    </b-col>
                  
                    <b-col cols="12" class="mt-3" v-if="sale.method == 'TWO_CREDIT_CARDS' && sale.status !== 'refused'">
                      <p class="label-campo">{{$t('shared.text_installment')}}</p>
                      <b-badge class="installment-b-badge">
                        <p class="installment-p">
                          {{$t('shared.text_installment_explanation')}}   
                          <template v-if="sale.base_currency">
                            {{ sale.base_currency.currency_symbol }}
                          </template>
                          {{ formatMoney(+sale.total) }}
                          {{$t('shared.text_installment_explanation_2')}}
                        </p>
                      </b-badge>
                    </b-col>

                    <!-- National/International Sale -->
                    <b-col cols="12" class="mt-2">
                      <p class="dados-campo mt-2">
                        <b-badge
                          variant="secondary"
                          v-if="sale.language == 'pt' || !sale.language"
                        >
                          🇧🇷 {{$t('shared.text_2061')}}
                        </b-badge>
                        <b-badge
                          variant="secondary"
                          v-if="sale.language && sale.language != 'pt'"
                        >
                          🌎 {{$t('shared.text_2062')}}
                        </b-badge>
                      </p>
                    </b-col>
                    <!-- Fees -->
                    <b-col
                      v-if="
                        sale.product &&
                        sale.product.installments > 1 &&
                        sale.product.no_interest_installments != null
                      "
                      cols="12"
                      class="mt-2"
                    >
                      <p class="label-campo">{{ $t('shared.text_2437') }}</p>
                      <p class="dados-campo mt-2">
                        <b-badge variant="primary"
                          >{{
                            sale.product.no_interest_installments
                              ? $t('shared.text_2063')
                              : $t('shared.text_2064')
                          }}{{ $t('shared.text_2065') }}</b-badge
                        >
                      </p>
                    </b-col>
                  </b-row>
                  <b-row v-show="isHeaven">
                    <!-- National/International -->
                    <b-col cols="12" class="mt-2">
                      <p class="dados-campo mt-2">
                        <!-- <b-badge v-if="sale.language == 'pt' || !sale.language"
                          variant="secondary"
                        >
                          🇧🇷 Venda nacional
                        </b-badge> -->
                        <!-- <b-badge v-if="sale.language && sale.language != 'pt'"
                          variant="secondary"
                        >
                          🌎 Venda internacional
                        </b-badge> -->
                      </p>
                    </b-col>
                    <!-- Local Currency -->
                    <b-col v-if="sale.local_currency != undefined" cols="12" class="mt-2">
                      <p class="label-campo mr-2">{{ $t('seller.contracts.right_preview.text_253') }}</p>
                      <span>{{ sale.local_currency.name }}</span>
                    </b-col>
                    <!-- Base Currency -->
                    <b-col v-if="sale.base_currency != undefined" cols="12" class="mt-4">
                      <p class="mr-2 label-campo">{{ $t('seller.contracts.right_preview.text_254') }}</p>
                      <section class="d-flex align-items-center">
                        <div class="chip" :style="setColors(sale.base_currency.currency)">
                          {{ sale.base_currency.currency_symbol }}
                        </div>
                        <span> {{ sale.base_currency.currency }}</span>
                      </section>
                    </b-col>
                    <!-- Country -->
                    <b-col v-if="sale.country != undefined" cols="12" class="mt-4">
                      <p class="mr-2 label-campo">{{ $t('seller.contracts.right_preview.text_255') }}</p>
                      <section class="d-flex align-items-center">
                        <img class="mr-2" style="height: 30px;" :src="require(`@/assets/locales/flag-${sale.country.country_code}.png`)" />
                        <span> {{ sale.country.name }}</span>
                      </section>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row class="item-dados">
                <b-col>
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{$t('shared.text_2066')}}</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          v-if="
                            !loading &&
                            sale &&
                            sale.product &&
                            sale.product.images &&
                            sale.product.images.length
                          "
                          :src="sale.product.images[0].path"
                        />
                      </div>
                      <div class="name-product" v-if="!loading && sale.product">
                        <span>{{ $t('shared.text_2066') }}: #{{ sale.product.id }}</span>
                        <template v-if="!sale.proposal">
                          <p v-if="sale.product">{{ sale.product.name }}</p>
                          <p v-else>{{ sale.invoices[0].product.name }}</p>
                          <p class="hash" v-if="sale.offer">{{ $t('shared.text_2139') }}: {{ sale.offer.hash }}</p>
                          <p class="hash" v-if="sale.invoices &&  sale.invoices[0] && sale.invoices[0].offer.hash">Código da Oferta: {{ sale.invoices[0].offer.hash }}</p>
                        </template>
                        <template v-else>
                          <p>
                            {{ sale.proposal.name }}
                          </p>
                          <p class="hash" v-if="sale.offer">
                            {{ $t('shared.text_2067') }} {{ sale.offer.hash }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
              <!-- Avaliação -->
              <b-row v-if="sale.product_rating" class="item-dados">
                <b-col>
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{$t('shared.text_2068')}}</h5>

                  <div class="avaliacao-item">
                    <b-form-rating
                      id="rating"
                      v-model="sale.product_rating"
                      variant="warning"
                      class="mb-2"
                      readonly
                      show-value
                      show-value-max
                      size="lg"
                    ></b-form-rating>
                  </div>
                  <b-col
                    v-if="sale.avaliation && sale.avaliation.length"
                    class="product-avaliacao text-avaliacao"
                  >
                    <img src="@/assets/img/icons/info-prod.svg" />
                    {{ sale.avaliation[0].avaliation_value }}
                  </b-col>
                </b-col>
              </b-row>

              <!-- Cupom -->
              <b-row v-if="sale.coupon_id" class="item-dados">
                <b-col v-if="sale.coupon">
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{$t('shared.text_2069')}}</h5>
                  <p class="label-campo">
                    <span class="title-item">{{ $t('shared.text_2070') }}</span>
                    <span>{{ sale.coupon.name }}</span>
                  </p>

                  <p class="label-campo">
                    <span class="title-item">{{$t('shared.text_2071')}}</span>
                    <span v-if="sale.coupon.type == 'AMOUNT'">
                      <template v-if="sale.base_currency">
                        {{ sale.base_currency.currency_symbol }}
                      </template>
                      {{ formatMoney(sale.coupon.amount) }}
                    </span>
                    <span v-if="sale.coupon.type == 'PERCENTAGE'">
                      {{ sale.coupon.amount }}%
                    </span>
                  </p>
                </b-col>
              </b-row>

              <!-- Affiliate -->
              <b-row v-if="sale.affiliate" class="item-dados">
                <b-col>
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{$t('shared.text_2072')}}</h5>
                  <p class="label-campo">
                    <span class="title-item">{{$t('shared.text_2073')}}</span>
                    <span>{{ sale.affiliate.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">{{$t('shared.text_2074')}}</span>
                    <span>{{ sale.affiliate.email }}</span>
                  </p>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row class="item-dados" v-if="sale.client">
                <b-col>
                  <!-- Name -->
                  <h5
                    v-if="sale.client && sale.client.name"
                    class="titulo-item"
                    :class="{'heaven': isHeaven}"
                  >
                    {{ $t('shared.text_2075') }}
                  </h5>
                  <p class="label-campo" v-if="sale.client.name">
                    <span class="title-item">{{$t('shared.text_2073')}}</span>
                    <span>{{ sale.client.name }}</span>
                  </p>
                  <!-- E-mail -->
                  <p class="label-campo" v-if="sale.client.email">
                    <template v-if="sale.seller_id == userID || ($store.getters.setPanel && canEditEmail && $store.getters.setPanel == sale.seller_id)">
                      <span class="title-item">{{$t('shared.text_2074')}}</span>
                      <span>
                        <changeEmail
                          :sale_id="sale.id"
                          :client_id="sale.client_id"
                          from="sale"
                          @close="onCloseChangeEmail"
                        >
                          <template v-slot:button="{ openModal }">
                            {{ sale.client.email }}
                            <font-awesome-icon
                            id="email-usuario"
                            style="cursor:pointer"
                            :icon="['fas', 'edit']"
                            @click="openModal(sale_id)"
                            />

                            <b-tooltip
                            target="email-usuario"
                            :title="$t('shared.text_2140')"
                            />

                          </template>
                        </changeEmail>
                      </span>
                    </template>
                    <span v-if="$store.getters.setPanel && !canEditEmail && $store.getters.setPanel == sale.seller_id">
                      <span class="title-item">{{$t('shared.text_2074')}}</span>
                      {{ sale.client.email }}
                    </span>
                  </p>
                  <!-- Cellphone / WhatsApp -->
                  <p v-if="sale.client.cellphone || sale.cellphone" class="label-campo">
                    <span class="title-item">{{$t('shared.text_2076')}}</span>
                    <a
                      :href="formatTel(sale.client ? sale.client.cellphone : sale.cellphone)"
                      target="_blank"
                      class="whats-cliente"
                      >{{ sale.client.cellphone }}
                      <font-awesome-icon :icon="['fab', 'whatsapp']"
                    /></a>
                  </p>
                  <!-- Document -->
                  <p v-if="sale.client.document || sale.client.cpf" class="label-campo">
                    <span class="title-item">{{ sale.client.document_type ? sale.client.document_type : 'Documento'}}</span>
                    <span>{{ openType != "lead" ? sale.client.document : sale.client.cpf }}</span>
                  </p>
                  <!-- Address -->
                  <div v-if="sale.client && sale.client.zipcode" class="mt-2">
                    <p class="label-campo">
                      <span class="title-item">{{ $t('shared.text_2078') }}</span>
                      <span>{{ sale.client.zipcode }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">{{ $t('shared.text_2079') }}</span>
                      <span>{{ sale.client.street }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">{{$t('shared.text_2080')}}</span>
                      <span>{{ sale.client.number }}</span>
                    </p>
                    <p class="label-campo" v-if="sale.client.complement">
                      <span class="title-item">{{$t('shared.text_2081')}}</span>
                      <span>{{ sale.client.complement }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">{{$t('shared.text_2082')}}</span>
                      <span>{{ sale.client.neighborhood }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">{{$t('shared.text_2083')}}</span>
                      <span>{{ sale.client.city }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">{{$t('shared.text_2084')}}</span>
                      <span>{{ sale.client.uf }}</span>
                    </p>
                  </div>
                </b-col>
              </b-row>

              <!-- Country -->
              <b-row v-if="openType === 'lead' && sale.country" class="item-dados">
                <b-col class="sumary">
                  <b-row>
                    <b-col v-if="sale.country" cols="12">
                      <p class="mr-2 label-campo mb-2">País de origem</p>
                      <section class="d-flex align-items-center">
                        <img class="mr-2" style="height: 30px;" :src="require(`@/assets/locales/flag-${sale.country.country_code}.png`)" />
                        <span> {{ sale.country.name }}</span>
                      </section>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Shipping -->
              <b-row class="item-dados" v-if="sale.shipping_amount">
                <b-col>
                  <h5 class="titulo-item">{{ $t('shared.text_2085') }}</h5>

                  <div v-if="sale.shipping_selected" class="frete-item">
                    <div class="infos-frete">
                      <img :src="sale.shipping_selected.company.picture" />
                      <div class="info-envio">
                        <span>
                          {{ sale.shipping_selected.name }}
                        </span>
                        <span v-if="sale.shipping_selected.delivery_range">
                          {{ sale.shipping_selected.delivery_range.min }} à
                          {{ sale.shipping_selected.delivery_range.max }}
                          {{$t('shared.text_2086')}}
                        </span>
                        <span class="frete-valor">
                          <template v-if="sale.base_currency">
                            {{ sale.base_currency.currency_symbol }}
                          </template>
                          {{ formatMoney(+sale.shipping_selected.price) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="sale.product.type_shipping_fee === 'FIXED'" class="frete-item">
                    {{ $t('shared.text_2087_1') }}:
                    {{ sale.base_currency && sale.base_currency.currency_symbol ? sale.base_currency.currency_symbol : 'R$' }}
                    {{ formatMoney(sale.product.amount_fixed_shipping_fee) }}
                  </div>
                  <div v-else class="frete-item">{{ $t('shared.text_2087') }}</div>
                </b-col>
              </b-row>

              <!-- Nota Fiscal -->
              <SaleDetailsFiscalCenter
                v-if="sale.note_by && sale.fiscalCenter.status && !(sale.fiscalCenter.status === 'NOT_ISSUED' && sale.note_by === 'Coprodutor')"
                :sale="sale"
                @update-loading="(n) => loading = n"
                @update-sale="fetchSale"
              />

              <!-- Participações -->
              <b-row v-if="openType != 'lead' && distribuitions.length" class="item-dados">
                <h5
                  v-b-toggle.collapse-2
                  class="titulo-item titulo-arrow"
                  :class="{ 'ativo': collapse, 'heaven': isHeaven }"
                  @click="collapse = !collapse"
                >
                  {{ $t('shared.text_2093') }}
                  <img src="@/assets/img/icons/arrow-fill.svg" alt="" />
                </h5>

                <b-collapse id="collapse-2" data-anima="top">
                  <b-row class="mt-3 mb-1">
                    <div class="label-campo"></div>
                  </b-row>
                  <b-row class="mt-3 mb-1">
                    <div class="grid-participations">
                      <span class="subtitle-item">{{ $t('shared.text_2094') }}</span>
                      <span class="desctitle-item" style="color: #2133d2">
                        <template v-if="sale.base_currency">
                          {{ sale.base_currency.currency_symbol }}
                        </template>
                        {{ formatMoney(sale.amount) }}
                      </span>
                    </div>
                  </b-row>
                  <b-row
                    v-for="distribuition in distribuitions"
                    :key="distribuition.id"
                  >
                    <div class="grid-participations">
                      <span class="subtitle-item">
                        {{
                          distribuition.type
                            ? getParticipations(distribuition.type)
                            : getParticipations(distribuition.role)
                        }}
                        {{ distribuition.type ? "" : `"${distribuition.name}"` }}
                      </span>
                      <span v-if="sale.base_currency"
                        class="desctitle-item"
                        style="color: rgb(255, 12, 55)"
                      >
                        - {{ sale.base_currency.currency_symbol }}
                        {{
                          distribuition.value
                            ? formatMoney(distribuition.value)
                            : formatMoney(distribuition.balance.balance)
                        }}
                      </span>
                    </div>
                  </b-row>
                  <b-row class="mt-3 mb-1">
                    <div class="grid-participations">
                      <span class="subtitle-item">{{ $t('shared.text_2438') }}</span>
                      <span class="desctitle-item" style="color: #009488">
                        <template v-if="sale.base_currency">
                          {{ sale.base_currency.currency_symbol }}
                        </template>
                        <template v-if="sale.product.type_shipping_fee === 'FIXED' && sale.product.amount_fixed_shipping_fee && sale.product.amount_fixed_shipping_fee > 0">
                          {{
                            (sale.balance - sale.product.amount_fixed_shipping_fee) > 0
                              ? formatMoney(sale.balance - sale.product.amount_fixed_shipping_fee)
                              : formatMoney((sale.balance - sale.product.amount_fixed_shipping_fee*(-1)))
                          }}
                        </template>
                        <template v-else>
                          {{ formatMoney(sale.balance) }}
                        </template>
                      </span>
                    </div>
                  </b-row>
                </b-collapse>
              </b-row>

              <!-- Boleto -->
              <b-row
                v-if="
                  sale.method == 'BOLETO' &&
                  !sale.affiliate &&
                  openType != 'lead'
                "
                class="item-dados"
              >
                <b-col>
                  <h5 class="titulo-item">{{ $t('shared.text_2095') }}</h5>
                  <p class="label-campo" v-if="sale.boleto_url || sale.order.boleto_url">Link</p>
                  <p class="dados-campo">
                    <a
                      :href="sale.boleto_url || sale.order.boleto_url"
                      target="_blank"
                      class="dados-campo"
                      >{{ sale.boleto_url || sale.order.boleto_url }}</a
                    >
                  </p>

                  <p class="label-campo mt-2" v-if="isSeller">
                    {{ $t('shared.text_2096') }}
                  </p>
                  <p class="dados-campo">
                    {{
                      sale.boleto_barcode
                        ? sale.boleto_barcode
                        : sale.order.boleto_barcode
                    }}

                    <font-awesome-icon
                      icon="copy"
                      style="cursor: pointer; margin-left: 5px"
                      @click="copy"
                    />
                  </p>
                </b-col>
              </b-row>

              <!-- Options -->
              <b-row class="item-dados" v-if="(!$store.getters.setPanel || canEditSale)">
                <b-col>
                  <div class="btn-flex" v-if="openType != 'lead'">
                    <Refund
                      v-if="
                        sale.method !== 'FREE' &&
                        (!$store.getters.setPanel || canEditSale) &&
                        (sale.status == 'refund_pending' ||
                        sale.status == 'paid' ||
                        (sale.status == 'canceled' && !sale.affiliate))
                      "
                      @close="fetchSale"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="outline-danger"
                          @click="openModal(sale_id, false, sale.order_id)"
                          :disabled="sale.status == 'refund_pending'"
                        >
                          {{
                            sale.status == "refund_pending"
                              ? $t('shared.text_2097')
                              : $t('shared.text_2098')
                          }}
                        </BaseButton>
                      </template>
                    </Refund>

                    <template>
                      <BaseButton
                        v-if="
                          (!$store.getters.setPanel || canEditSale) &&
                            sale.status == 'paid'
                        "
                        class="re-send-email"
                        variant="outline-warning"
                        @click="sendEmail(sale_id)"
                      >
                        {{ $t('resend-email') }}
                      </BaseButton>
                    </template>

                    <template>
                      <BaseButton
                      v-if="showRefundButton"
                        class="re-send-email"
                        variant="outline-warning"
                        @click="openReceipt(sale_id)"
                        >
                        {{ $t('generate-refund-receipt') }}
                      </BaseButton>
                    </template>

                    <Affiliation
                      v-if="
                        !$store.getters.setPanel &&
                        sale.seller_id == userID &&
                        !sale.affiliate &&
                        sale.status == 'paid'
                      "
                      :product_id="sale.product.id"
                      from="sale"
                      @close="fetchSale"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="outline-info"
                          @click="openModal(sale_id)"
                        >
                          {{ $t('shared.text_2099') }}
                        </BaseButton>
                      </template>
                    </Affiliation>

                    <BaseConfirmDialog
                      v-if="
                        !$store.getters.setPanel &&
                        sale.method == 'BOLETO' &&
                        sale.status == 'waiting_payment' &&
                        !sale.affiliate
                      "
                      @confirmed="updateSale"
                      class="ml-2 d-inline-block"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton variant="outline-info" @click="openModal"
                          >{{ $t('shared.text_2100') }}</BaseButton
                        >
                      </template>
                    </BaseConfirmDialog>
                  </div>

                  <TicketReassignment
                      v-if="validationTicketReassignment"
                      from="sale"
                      @close="fetchSale"
                      :sale_id="sale?.ticket[0]?.sale_id"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          @click="openModal"
                          class="re-send-email"
                          variant="outline-dark-green"
                          :disabled="!eventTicketDate"
                        >
                          {{ $t("ticket_sale_preview.text_1") }}
                        </BaseButton>
                      </template>
                  </TicketReassignment>

                  <SaleExpress v-if="canShowSaleExpress" @close="fetchSale">
                    <template v-slot:button="{ openModal }">
                      <BaseButton
                          variant="success"
                          class="w-100"
                          :disabled="!is_sale_express_enabled"
                          @click="openModal(sale_id)"
                      >
                        {{ $t('shared.sale_express') }}
                      </BaseButton>
                    </template>
                  </SaleExpress>

                  <div
                    v-if="openType === 'lead' && sale.cellphone && !$store.getters.setPanel"
                    class="btn-flex"
                  >
                    <BaseButton
                      class="mt-3"
                      variant="primary"
                      :class="{'heaven': isHeaven}"
                      @click="openLink(formatTel(sale.cellphone))"
                    >
                      {{ $t('shared.text_2101') }}
                    </BaseButton>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="openType !== 'lead'" class="item-dados">
                <b-col>
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{ $t('shared.text_2102') }}</h5>
                  <div v-if="sale && sale.metas && sale.metas.length">
                    <div
                      v-for="meta in sale.metas"
                      :key="meta.meta_key"
                      class="meta-item"
                    >
                      <p class="key-meta">• {{ meta.meta_key }}</p>
                      <p class="key-value">{{ meta.meta_value }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="sem-historico">
                      {{ $t('shared.text_2103') }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import SaleService from "@/services/resources/SaleService";
import LeadService from "@/services/resources/LeadService";
import ContractService from '@/services/resources/ContractService'
import sendEmailService from "@/services/resources/SendEmailService";
import Money from "@/mixins/money";
import Refund from "./modals/RefundSolicitationMotives";
import Affiliation from "./modals/Affiliation";
import TicketReassignment from "./modals/TicketReassignment";
import ChangeEmail from "./modals/ChangeEmail";
import errorPayment from "@/utils/errorPayment.js";
import Cookies from "js-cookie";
import { EventBus } from "@/main.js";
import SaleDetailsFiscalCenter from '@/components/Seller/Sales/details/SaleDetailsFiscalCenter'
import { symbolCurrency } from '@/helpers.js'
import SaleExpress from "./modals/SaleExpress.vue";
import {mapGetters} from "vuex";
import gtagSend from '@/utils/gtagSend';
import moment from "moment";


const serviceContract = ContractService.build()
const serviceSale = SaleService.build();
const serviceLead = LeadService.build();
const serviceSendEmail = sendEmailService.build();

export default {
  name: 'DetalhesVenda',
  components: {SaleExpress, Refund, Affiliation, ChangeEmail, SaleDetailsFiscalCenter, TicketReassignment},
  mixins: [Money],
  props: {
    updateQueryParams: {
      type: Function,
      default: () => {},
    },
    source: {
      type: String,
      default: 'salePreview'
    }
  },
  data() {
    return {
      distribuitions: [],
      loading: true,
      visible: false,
      collapse: false,
      id_contract: "",
      sale_id: -1,
      sale: {
        avaliation: [],
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
        refund_solicitations: [],
      },
      openType: null,
      canEditSale: false,
      canEditEmail: false,
      saleRefund: {},
    };
  },
  computed: {
    ...mapGetters(['is_sale_express_enabled', 'getFlags']),
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    validationTicketReassignment(){
      if(
        (!this.$store.getters.setPanel || this.canEditSale) && 
        this.sale && this.sale.ticket && this.sale.ticket.length > 0 && 
        this.sale.status == 'paid'
      ){
        return true;
      }
      return false
    },
    eventTicketDate(){
      if(this.sale?.product?.start_date && this.sale?.product?.start_time) {
        let dataStart = moment(this.sale.product?.start_date);
        let timeStart = moment(this.sale.product?.start_time, 'HH:mm');
        let dateTimeStart = moment(`${dataStart.format('YYYY-MM-DD')} ${timeStart.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
        let dataCurrent = moment();
        return dataCurrent.isBefore(dateTimeStart);
      }

      return false
    },
    refundRequested() {
      if (this.sale.refund_solicitations != null) {
        for (var i = 0; i < this.sale.refund_solicitations.length; i++)
          if (
            this.sale.refund_solicitations[i].status == "REQUESTED" &&
            this.sale.status != "refunded"
          )
            return true;
      }

      return false;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    showRefundButton(){
      let result = false;
      if(this.sale && this.sale.status == 'refunded') {
        if(this.sale.method === 'BOLETO' || this.sale.method === 'TWO_CREDIT_CARDS' || this.sale.method === 'CREDIT_CARD' || this.sale.method === 'PIX' || this.sale.method === 'PAYPAL')
        result = true;
      }

      return result
    },
    canShowSaleExpress() {
      return this.getFlags.includes("second_sale") && this.source === "salePreview";
    }
  },
  methods: {
    onCloseChangeEmail() {
      this.$emit('update-sales')
      this.fetchSale()
    },
    sendEmail(sale_id) {
      serviceSendEmail
        .create({
          id: sale_id,
        })
        .then((response) => {
          this.$bvToast.toast(this.$t('resend_email_success'), {
            variant: "success",
            title: this.$t('resend_email_success_title'),
            autoHideDelay: 5000,
            appendToast: true,
          });
          gtagSend('clique_reenviar_email_compra', {
            ID_Cliente: this.$store.getters.getProfile.id,
            ID_Venda: sale_id,
        });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id);
      }
      return "R$";
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "SaleEdit")) {
            this.canEditSale = true;
          }
          if (dados.find((x) => x.name === "SaleClientEmailEdit")) {
            this.canEditEmail = true;
          }
        }
      }
    },
    checkError(error) {
      return errorPayment(error);
    },
    getParticipations(status) {
      switch (status) {
        case "AFFILIATE_FEE": return this.$t('shared.text_2104')
        case "ANTECIPATION_FEE": return this.$t('shared.text_2105')
        case "SELLER_FEE": return this.$t('shared.text_2106')
        case "SELLER": return this.$t('shared.text_2107')
        case "MARKETPLACE_FEE": return this.$t('shared.text_2108')
        case "USER_ANTECIPATION_FEE": return this.$t('shared.text_2109')
        case "SELLER_FEE_INTERNATIONAL": return this.$t('shared.text_2110')
        case "SELLER_INTEREST_FEE": return this.$t('shared.text_2111')
        case "CO_SELLER": return this.$t('shared.text_2112')
        case "AFFILIATE": return this.$t('shared.text_2113')
        case "CURRENCY_CONVERSION_FEE": return this.$t('shared.text_2137')
        case "COUNTRY_FEE": return this.$t('shared.text_2138')
        case "RS_DONATION": return this.$t('shared.text_2141')
        default: return status
      }
    },
    getMethod(method) {
      return this.textMethod(method)
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "")
          if(tell[0] === '+') tell = tell.substring(1)
          return `https://api.whatsapp.com/send?phone=${tell}`
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(/[() -]/g, "")}`
        }
      }
    },
    getStatus(status) {
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": return { text: this.$t('shared.text_2114'), class: "icon-blue" }
          case "authorized": return { text: this.$t('shared.text_2115'), class: "icon-blue" }
          case "pending_approbation": return { text: this.$t('shared.text_2116'), class: "icon-blue" }
          case "paid": return { text: this.$t('shared.text_2117'), class: "icon-green" }
          case "refunded": return { text: this.$t('shared.text_2118'), class: "icon-red" }
          case "waiting_payment": return { text: this.$t('shared.text_2119'), class: "icon-warning" }
          case "pending_refund":
          case "refund_pending": return { text: this.$t('shared.text_2120'), class: "icon-warning" }
          case "refused": return { text: this.$t('shared.text_2121'), class: "icon-red" }
          case "chargedback": return { text: this.$t('shared.text_2122'), class: "icon-warning" }
          case "delayed": return { text: this.$t('shared.text_2123'), class: "icon-warning" }
          default: return { text: status, class: "icon-black" }
        }
      } else return { text: status, class: "icon-black" }
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
      this.updateQueryParams(true); // ao enviar o true, a listagem de vendas não será atualizada
    },
    async openPanel(sale_id, openType) {
      this.getPermissions();
      this.verifySaleExpressSession();
      await this.$store.dispatch('fetchFlags')
      this.openType = openType ? openType : null;
      this.visible = true;
      this.sale_id = sale_id;
      openType === "lead" ? this.fetchLead() : this.fetchSale();
    },
    verifySaleExpressSession() {
      this.$store.dispatch('refreshSaleExpressSession');
    },
    copy() {
      const el = document.createElement("textarea");
      el.value = this.sale.boleto_barcode
        ? this.sale.boleto_barcode
        : this.sale.order.boleto_barcode;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t('shared.text_2124'), {
        variant: "success",
        title: this.$t('shared.text_2048'),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    getCoSellerPercentage() {
      if (this.sale.product.co_sellers) {
        for (let i = 0; i < this.sale.product.co_sellers.length; i++) {
          if (this.sale.product.co_sellers[i].user_id == this.userID) {
            return this.sale.product.co_sellers[i].percentage;
          }
        }
      }

      return 0;
    },
    getCoSellerAmount() {
      if (this.sale.product.co_sellers) {
        for (let i = 0; i < this.sale.product.co_sellers.length; i++) {
          if (this.sale.product.co_sellers[i].user_id == this.userID) {
            const percentage = this.sale.product.co_sellers[i].percentage;
            return ((this.sale.amount - this.sale.fee) * percentage) / 100;
          }
        }
      }

      return 0;
    },
    fetchContract() {
      let data = {
        id: "0?contract_id=" + this.sale.contract_id + "&client_id=" + this.sale.client_id,
      }

      serviceContract
        .read(data)
        .then((response) => {
          this.id_contract = response.data.id || null;
        })
        .catch((err) => {
          console.error(err);
          this.id_contract = null;
        });
    },
    fetchSale() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
      };

      this.loading = true

      serviceSale
        .read({ id: this.sale_id })
        .then(response => {
          this.sale = response;
          this.distribuitions = response.distribuition
            ? response.distribuition
            : [];

          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          }

          if (this.sale.shipping_selected) {
            this.sale.shipping_selected = JSON.parse(
              this.sale.shipping_selected
            ).frete;
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    fetchLead() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
      };

      this.loading = true

      serviceLead
        .read({ id: this.sale_id })
        .then(response => {
          this.sale = response;
          this.sale.client = response;
          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          } else {
            this.loading = false;
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    updateSale() {
      let data = {
        id: this.sale_id,
        status: "pending_approbation",
      };

      serviceSale
        .update(data)
        .then(response => {
          if (response.success) {
            this.fetchSale();
            EventBus.$emit('relist-sales')
          }
        })
        .catch(err => console.error(err))
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    setColors(coin) {
      if(coin === 'BRL') return "color: rgba(0, 132, 92, 1);"
      if(coin === 'USD') return "color: rgba(255, 203, 55, 1);"
      if(coin === 'EUR') return "color: rgb(40 30 176);"
      if(coin === 'GBP') return "color: rgba(0, 0, 0, 1);"
      return "color: #21a56e;"
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },

    openReceipt(sale_id){


      let width = 750;
      let height = 900;

      let left = (window.screen.width / 1.4) * -1;
      let top = (window.screen.height - height) / 2;
      let parameters_html = `width=${width},height=${height},left=${left},top=${top}`;

      if(this.sale.method === 'BOLETO'){
        window.open(`/recibos/boletos/${sale_id}`, 'Boleto PDF', parameters_html);
      } else if (this.sale.method === 'PIX'){
        window.open(`/recibos/pix/${sale_id}`, 'Pix PDF', parameters_html);
      } else {
        window.open(`/recibos/cartoes/${sale_id}`, 'Cartões PDF', parameters_html);
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.panel {
  .sumary {
    font-size: 13px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }

  .re-send-email {
    margin-bottom: 13px;
    width: 100%;
  }
  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
.detalhe-item:first-child img {
  margin-right: 23px;
}
.detalhe-item:nth-child(2) img {
  margin-right: 28px;
}
.detalhe-item:nth-child(3) img {
  margin-right: 20px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $base-color;
  margin-bottom: 15px;
}
.titulo-item.heaven {
  font-size: 18px;
  font-weight: 600;
  color: $base-color-heaven;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.subtitle-item {
  font-size: 14px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.desctitle-item {
  font-size: 14px;
  color: #333;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 5px;
}

.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button,
.btn-flex button {
  width: 100%;
  height: 55px;
}
.whats-cliente {
  font-size: 14px;
  color: #2133d2;
}
.whats-cliente svg {
  margin-left: 5px;
}
.installment-b-badge{
  margin-top: 10px;
}
.installment-p{
  padding: 2px 6px 2px 6px;
  width: 100%;
  font-weight: 400;
}

// metas

.meta-item + .meta-item {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
  padding: 10px 0;
}

.meta-item .key-meta {
  font-weight: 600;
  margin-bottom: 5px;
}
.meta-item .key-value {
  font-weight: 500;
  margin-left: 10px;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}

.decoration-none {
  text-decoration: none;
}

.btn-link-contract {
  display: flex;
  align-items: center;
  color: #2133d2;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px !important;
  border-radius: 5px;
  height: 45px;
}

.btn-link-contract img {
  margin-right: 10px;
}
.product-avaliacao {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 7px;
  font-size: 14px;
}

.product-avaliacao + .product-avaliacao {
  margin-top: 25px;
}
.product-avaliacao.text-avaliacao {
  font-weight: normal;
  align-items: flex-start;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product .hash {
  font-size: 12px;
  color: #81858e;
  font-weight: inherit;
}
.name-product p {
  font-weight: 600;
}
.nota-status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.badge-primary,
.badge-secondary {
  padding: 5px 8px;
  font-weight: 600;
  background-color: rgba(255, 185, 55, 0.2);
  color: #ffb937;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
  font-size: 12px;
}
.badge-secondary {
  background: #ededf0;
  color: #333;
  padding: 10px;

  font-size: 13px;
}

// frete

.frete-item img {
  max-width: 80px;
  margin-bottom: 15px;
}

.frete-item .infos-frete {
  display: block;
  font-size: 15px;
  margin-bottom: 7px;
}

.frete-item .info-envio,
.frete-item .info-envio span {
  display: block;
  margin-bottom: 7px;
}

.info-envio .frete-valor {
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  .frete-item .infos-frete {
    width: 100%;
  }

  .frete-item .info-envio {
    grid-template-columns: 1fr;
  }

  .info-envio span {
    font-size: 13px;
  }

  .info-envio span + span {
    margin-top: 3px;
  }
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

.collapse.show {
  width: 100%;
}

.grid-participations {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.grid-participations + .grid-participations {
  margin-top: 7px;
}
.chip {
  width: 30px;
  height: 30px;
  font-size: 15px;
  font-weight: 700;
  border:  1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
</style>